import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserAPIService } from 'src/app/_api-services';
import { ConfirmationCodeType } from 'src/app/_enums';
import { URLValidator } from 'src/app/_helpers/url.validator';
import { CountrySelector } from 'src/app/_models';
import { AuthenticationService, HeaderService, SocketioService } from 'src/app/_services';
import { CommonValidators } from 'src/app/_validators/common.validators';

@Component({
  selector: 'login',
  templateUrl: './login.component.html',
  styleUrl: './login.component.scss'
})
export class LoginComponent implements OnDestroy {
    @Output() phoneSubmitted: EventEmitter<string> = new EventEmitter<string>()
    
    private redirectUrl: string | null = null
    private subscriptions: Subscription = new Subscription()
    
    template: string = 'login-phone'
    phoneNumber: string = ''
    confirmationCodeType: ConfirmationCodeType = ConfirmationCodeType.Login
    
    selectedCountry?: CountrySelector
    isLoading: boolean = false
    serverError: string | null = null

    constructor(
        private authService: AuthenticationService,
        private route: ActivatedRoute,
        private router: Router,
        private urlValidator: URLValidator,
        private socketioService: SocketioService,
        private headerService: HeaderService,
    ) {
        this.headerService.setHeaderState(false, true)
        
        this.route.queryParams.subscribe( params => {
            this.redirectUrl = params['redirect']
        })
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    setPhone(phone: string): void {
        this.phoneNumber = phone
    }
    
    goTemplate(t: string): void {
        this.template = t
    }
    
    doLogin(data: {phone: string, code: string}): void {
        if(this.isLoading) {
            return
        }
        
        this.serverError = null
        this.isLoading = true

        
        this.authService.login(data.phone, data.code).subscribe( {
            next: (res) => {
                this.socketioService.connect()
                this.isLoading = false
                if(this.redirectUrl && this.urlValidator.isValidRedirect(this.redirectUrl)) {
                    this.router.navigate([this.redirectUrl], { queryParamsHandling: 'preserve' })
                } else {
                    this.router.navigate([''])
                }
            },
            error: (err) => {
                this.isLoading = false
                this.serverError = err.error.message
                console.log(err.error)
            },
            complete: () => {
                this.isLoading = false
            }
        })
    }
}
