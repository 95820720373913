import { Component, ElementRef, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core'
import { FormBuilder, FormControl, FormGroup } from '@angular/forms'
import { ActivatedRoute } from '@angular/router'
import { Subscription } from 'rxjs'
import { UserAPIService } from 'src/app/_api-services'
import { ConfirmationCodeType } from 'src/app/_enums'
import { RegisterTemplate } from 'src/app/_enums/register-template.enum'
import { URLValidator } from 'src/app/_helpers'
import { AuthenticationService } from 'src/app/_services'

@Component({
  selector: 'confirm-phone',
  templateUrl: './confirm-phone.component.html',
  styleUrl: './confirm-phone.component.scss'
})
export class ConfirmPhoneComponent implements OnInit {
    @Input() phoneNumber!: string
    @Input() codeType!: ConfirmationCodeType
    
    @Output() onGoBack: EventEmitter<RegisterTemplate> = new EventEmitter<RegisterTemplate>()
    @Output() onConfirmPhone: EventEmitter<{phone: string, code: string}> = new EventEmitter<{phone: string, code: string}>()
    @Output() headerLink: EventEmitter<null> = new EventEmitter<null>()
    
    @ViewChildren('inputField') inputFields!: QueryList<ElementRef>
    
    private subscriptions: Subscription = new Subscription()
    
    codeForm: FormGroup
    canResend: boolean = false
    resendCountdown: number = 15
    sendingRequest: boolean = false
    ref: string = ''

    constructor(
        private fb: FormBuilder, 
        private authService: AuthenticationService,
        private userAPIService: UserAPIService,
        private route: ActivatedRoute,
        private urlValidator: URLValidator,
    ) {
        this.codeForm = this.fb.group({
            code0: new FormControl(''),
            code1: new FormControl(''),
            code2: new FormControl(''),
            code3: new FormControl(''),
            code4: new FormControl(''),
            code5: new FormControl('')
        })
        
        this.startCountdown()

        this.ref = this.urlValidator.getRefFromUrl(this.route)
    }
    
    ngOnInit(): void {
        this.headerLink.emit(null)
    }
    
    goBack(): void {
        this.onGoBack.emit(RegisterTemplate.phone)
    }
    
    startCountdown() {
        this.canResend = false
        this.resendCountdown = 15
        
        let i = setInterval(() => {
            this.resendCountdown--
            if(this.resendCountdown == 0) {
                this.canResend = true
                clearInterval(i)    
            }
        }, 1000)
    }

    onInputChange(index: number): void {
        const controlName = `code${index}`
        const control = this.codeForm.get(controlName)
        if (control?.value && index < 5) {
            this.inputFields.get(index + 1)?.nativeElement.focus()
        }
        
        this.checkIfComplete()
    }

    onBackspace(index: number, event: KeyboardEvent): void {
        if (event.key === 'Backspace' && !this.codeForm.get(`code${index}`)?.value && index > 0) {
            this.inputFields.get(index - 1)?.nativeElement.focus()
        }
    }
    
    onPaste(event: ClipboardEvent): void {
        event.preventDefault()
        const pastedText = event.clipboardData?.getData('text')
        
        if (pastedText && pastedText.length === this.inputFields.length) {
            Array.from(pastedText).forEach((char, i) => {
                this.codeForm.get(`code${i}`)?.setValue(char)
            })
            this.inputFields.get(this.inputFields.length - 1)?.nativeElement.focus()
            
            this.checkIfComplete()
        }
    }

    resendCode(): void {
        if(this.sendingRequest) {
            return
        }
        
        this.sendingRequest = true
        
        this.subscriptions.add(
            this.userAPIService.sendConfirmationCode(this.phoneNumber, this.codeType, this.ref).subscribe({
                next: () => {
                    this.startCountdown()
                    this.sendingRequest = false
                },
                error: err => {
                    console.log(err)
                    this.sendingRequest = false
                }
            })
        )
    }
    
    checkIfComplete(): void {
        const values = Object.values(this.codeForm.value)
        
        if (values.every(value => value)) {
          this.confirmCode(values.join(''))
        }
    }
    
    confirmCode(code: string): void {
        this.onConfirmPhone.emit({ phone: this.phoneNumber, code: code})
    }
}
