<register-header [link]="'/'" [state]="'signup'"></register-header>

<div class="container">
    <h1 class="text-center">Phone</h1>
    <p class="text-center" style="margin-bottom: 40px;">Please confirm your phone number</p>
    
    <form [formGroup]="loginForm">
        <div class="field-group">
            <country-selector (countrySelected)="countrySelected($event)"></country-selector>
        </div>
        
        <div class="field-group phone-group">
            <span class="country-code">{{ selectedCountry?.dialCode }}</span>
            <input 
                phoneMask 
                [preValue]="loginForm.value.phone" 
                [phoneControl]="loginForm.controls['phone']" 
                maxlength="14"
                formControlName="phone" 
                id="tel" type="tel" 
                class="phone-input" 
                placeholder="000 000 0000">
            <p *ngIf="phone.errors && phone.errors['required'] && phone.touched" class="error">Phone number is required</p>
            <p *ngIf="phone.errors && !phone.errors['required'] && phone.errors['invalidPhone'] && phone.touched" class="error">Invalid phone number</p>
        </div>
        
        
        <span *ngIf="serverError" class="error">{{ serverError }}</span>
        
        <div class="field-group">
            <button *ngIf="!isLoading" class="btn btn-primary btn-rounded btn-submit" (click)="sendText()">Continue</button>
            <loading-spinner *ngIf="isLoading"></loading-spinner>
        </div>
    </form>
</div>