import { Component, OnInit, Output, EventEmitter, ViewChild, } from '@angular/core';
import { FormBuilder, FormGroup, Validators, AbstractControl } from '@angular/forms';
import { Subscription } from 'rxjs';
import { UserAPIService } from 'src/app/_api-services';
import { HeaderService } from 'src/app/_services';
import { RegisterTemplate } from 'src/app/_enums/register-template.enum';
import { AlertPopupComponent } from 'src/app/shared/common/components/alert-popup/alert-popup.component';
import { Router } from '@angular/router';
import { CommonValidators } from 'src/app/_validators/common.validators';
import { CountrySelector } from 'src/app/_models';

@Component({
	selector: 'app-waitlist',
	templateUrl: './waitlist.component.html',
	styleUrls: ['./waitlist.component.scss']
})
export class WaitlistComponent implements OnInit {
	@Output() template: EventEmitter<RegisterTemplate> = new EventEmitter<RegisterTemplate>()
	@ViewChild('alertPopup') alertPopup!: AlertPopupComponent

	private subscriptions: Subscription = new Subscription()

	waitlistForm!: FormGroup
	isLoading: boolean = false
    selectedCountry?: CountrySelector
    phoneNumber: string = ''
	
	entityOptions: string[] = ['Individual', 'Business Owner', 'Community Organizer']
	interestsOptions: string[] = [
		'Connecting with friends and family',
		'Joining local communities',
		'Discovering new businesses',
		'Finding events and activities',
		'Adding my own business or event',
		'Other'
	]
	referredByOptions: string[] = [
		'Friend or Family',
		'Social Media',
		'Online Search',
		'News Article or Blog Advertisement',
		'Other'
	]

	constructor(
		private fb: FormBuilder,
		private userService: UserAPIService,
		private headerService: HeaderService,
		private router: Router,
	) {
		this.headerService.setHeaderState(false, true)
	}

	ngOnDestroy(): void {
		this.subscriptions.unsubscribe()
  	}

	ngOnInit(): void {
		this.waitlistForm = this.fb.group({
			first_name: ['', Validators.required],
			last_name: ['', Validators.required],
			email: ['', [Validators.required, Validators.email]],
            phone: ['', [Validators.required, CommonValidators.phone]],
			representing: ['', Validators.required],
			entity_representing: [''],
			interests: this.fb.group({
				'Connecting with friends and family': [false],
				'Joining local communities': [false],
				'Discovering new businesses': [false],
				'Finding events and activities': [false],
				'Adding my own business or event': [false],
				'Other': [false],
			}),
			referred_by: ['', Validators.required],
			referred_by_other: [''],
			wants_offers: [false],
			other_text: [''],
    	})
		
		this.waitlistForm.get('representing')?.valueChanges.subscribe(value => {
			const entityRepresenting = this.waitlistForm.get('entity_representing')
			if (entityRepresenting) {
				if (value === 'Business Owner' || value === 'Community Organizer') {
					entityRepresenting.setValidators([Validators.required])
				} else {
					entityRepresenting.clearValidators()
					entityRepresenting.setValue('')
				}
				entityRepresenting.updateValueAndValidity()
			}
		})
		
		this.waitlistForm.get('referred_by')?.valueChanges.subscribe(value => {
			const referredByOther = this.waitlistForm.get('referred_by_other')
			if (referredByOther) {
				if (value === 'Other') {
					referredByOther.setValidators([Validators.required])
				} else {
					referredByOther.clearValidators()
					referredByOther.setValue('')
				}
				referredByOther.updateValueAndValidity()
			}
		})
		
		this.waitlistForm.get('interests')?.get('Other')?.valueChanges.subscribe(value => {
			const otherText = this.waitlistForm.get('interests.other_text')
			if (otherText) {
				if (value) {
					otherText.setValidators([Validators.required])
				} else {
					otherText.clearValidators()
					otherText.setValue('')
				}
				otherText.updateValueAndValidity()
			}
		})

		this.applyConditionalValidators()
	}

	private applyConditionalValidators() {		
		const interestsGroup = this.waitlistForm.get('interests') as FormGroup
		const otherCheckbox = interestsGroup.get('Other')
		const otherTextControl = this.waitlistForm.get('other_text')

		if (otherCheckbox && otherTextControl) {			
			const sub = otherCheckbox.valueChanges.subscribe((isChecked: boolean) => {
				if (isChecked) {					
					otherTextControl.setValidators([Validators.required])
				} else {					
					otherTextControl.clearValidators()
					otherTextControl.setValue('')
				}				
				otherTextControl.updateValueAndValidity()
			})
			
			this.subscriptions.add(sub)
		}
	}
	
	get first_name(): AbstractControl {
		return this.waitlistForm.get('first_name')!
	}

	get last_name(): AbstractControl {
		return this.waitlistForm.get('last_name')!
	}
    
    get phone(): AbstractControl {
        return this.waitlistForm.get('phone')!
    }

	get email(): AbstractControl {
		return this.waitlistForm.get('email')!
	}

	get representing(): AbstractControl {
		return this.waitlistForm.get('representing')!
	}

	get entity_representing(): AbstractControl | null {
		return this.waitlistForm.get('entity_representing')
	}

	get referred_by(): AbstractControl {
		return this.waitlistForm.get('referred_by')!
	}

	get referred_by_other(): AbstractControl | null {
		return this.waitlistForm.get('referred_by_other')
	}

	get interests(): FormGroup {
		return this.waitlistForm.get('interests') as FormGroup
	}

	get other_text(): AbstractControl | null {
		return this.waitlistForm.get('other_text')
	}
    
    countrySelected(country: CountrySelector): void {
        this.selectedCountry = country
    }
    
    setPhoneValue(e: any): void {
        if(!this.selectedCountry) {
            return
        }
        
        this.phoneNumber = e.target.value
    }
	
	submitWaitlistForm(): void {
		if(this.isLoading) {
			return
		}

		if(!this.waitlistForm.valid) {
			this.waitlistForm.markAllAsTouched()
			return
		}

		this.isLoading = true

		const formData = this.prepareFormData()		
		this.subscriptions.add(this.userService.addToWaitlist(formData).subscribe({
			next: (res) => {
				this.template.emit(RegisterTemplate.avatar)
				this.showAlert(String(res.list_position))
			},
			error: (err) => {
				this.isLoading = false
				console.error(err)
			},
			complete: () => {
				this.isLoading = false
			}
		}))
	
	}
	
	private prepareFormData() {
		const selectedInterests = Object.keys(this.interests.value)
			.filter(key => this.interests.value[key])
		
		const processedInterests = selectedInterests.map(interest => {
			if (interest === 'Other') {
				const otherText = this.waitlistForm.get('other_text')?.value
				return otherText ? otherText : 'Other'				
			}
			return interest
		})
        
        const phone = this.selectedCountry?.dialCode + this.phone.value

		return {
			first_name: this.first_name.value,
			last_name: this.last_name.value,
            phone: phone,
			email: this.email.value,
			representing: this.representing.value,
			entity_representing: this.entity_representing?.value || null,
			interests: processedInterests,
			referred_by: (this.referred_by.value === 'Other' ? this.referred_by_other?.value : this.referred_by.value) || this.referred_by.value,			
			wants_offers: this.waitlistForm.get('wants_offers')?.value || false
		}
	}

	showAlert(position: string): void {
        this.alertPopup.title = 'Thank You!'
        this.alertPopup.message = `You've been added to the waitlist! You're position in line is #${position}.`
        this.alertPopup.buttons = [
            {
                name: 'OK',
                action: () => {
                    this.alertPopup.hide()
					this.router.navigate(['/'])
                }
            }
        ]
        this.alertPopup.show()
    }

	goBack(): void {
		this.router.navigate(['/'])
	}
}
