import { Component, EventEmitter, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { UserAPIService } from 'src/app/_api-services';
import { ConfirmationCodeType } from 'src/app/_enums';
import { CountrySelector } from 'src/app/_models';
import { HeaderService } from 'src/app/_services';
import { CommonValidators } from 'src/app/_validators/common.validators';

@Component({
  selector: 'login-phone',
  templateUrl: './login-phone.component.html',
  styleUrl: './login-phone.component.scss'
})
export class LoginPhoneComponent implements OnDestroy {
    @Output() phoneSubmitted: EventEmitter<string> = new EventEmitter<string>()
    
    private subscriptions: Subscription = new Subscription()
    
    loginForm: FormGroup
    selectedCountry?: CountrySelector
    isLoading: boolean = false
    serverError: string | null = null
    phoneNumber: string = ''
    
    constructor(
        private fb: FormBuilder,
        private headerService: HeaderService,
        private router: Router,
        private userAPIService: UserAPIService,
    ) {
        this.headerService.setHeaderState(false, true)
        
        this.loginForm = this.fb.group({
            phone: new FormControl('', [Validators.required, CommonValidators.phone])
        })
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    get phone() {
        return this.loginForm.get('phone')!
    }
    
    countrySelected(country: CountrySelector): void {
        this.selectedCountry = country
    }
    
    sendText(): void {
        this.serverError = ''
        if(!this.loginForm.valid) {
            this.loginForm.markAllAsTouched()
            return
        }
        
        if(!this.selectedCountry) {
            this.serverError = 'Please select a country'
            return
        }
    
        
        this.isLoading = true
        const phone = this.selectedCountry!.dialCode + this.loginForm.controls['phone'].value
        this.phoneNumber = phone
        
        this.subscriptions.add(
            this.userAPIService.sendConfirmationCode(phone, ConfirmationCodeType.Login).subscribe({
                next: () => {
                    this.isLoading = false
                    this.phoneSubmitted.emit(phone)
                },
                error: err => {
                    console.log(err)
                    this.serverError = err.error.message
                    this.isLoading = false
                }
            })
        )
    }
}
