import { Component } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'reset-password',
  templateUrl: './reset-password.component.html',
  styleUrl: './reset-password.component.scss'
})
export class ResetPasswordComponent {
    private subscriptions: Subscription = new Subscription()
    
    resetPasswordForm: FormGroup
    isLoading: boolean = false
    serverError: string | null = null
    showPassword: boolean = false
    resetEmail: string | null
    resetToken: string | null
    
    constructor(
        private fb: FormBuilder, 
        private router: Router,
        private route: ActivatedRoute,
        private authService: AuthenticationService
    ) {
        this.resetPasswordForm = this.fb.group({
            "password": new FormControl('', [Validators.required])
        })
        
        this.resetEmail = this.route.snapshot.queryParams['email']
        this.resetToken = this.route.snapshot.queryParams['token']
        
        // Dont wan't people on this page unless they have an email and token
        if(!this.resetEmail || !this.resetToken) {
            this.router.navigate([''])
        }
    }
    
    get password() {
        return this.resetPasswordForm.get('password')
    }
    
    resetPassword(): void {
        if(this.isLoading) {
            return
        }
        
        if(!this.resetPasswordForm.valid) {
            this.resetPasswordForm.markAllAsTouched()
            return
        }
        
        this.isLoading = true
        let password = this.resetPasswordForm.controls['password'].value
        
        this.subscriptions.add(
            this.authService.forgotPasswordReset(password, this.resetEmail!, this.resetToken!).subscribe({
                next: (res) => {
                    this.authService.setUser(res)
                    this.router.navigate([''])
                },
                error: (err) => {
                    this.serverError = err.error.message
                    this.isLoading = false
                },
                complete: () => {
                    this.isLoading = false
                }
            })
        )        
    }
    
    togglePasswordVisibility(): void {
        this.showPassword = !this.showPassword
    }
}
