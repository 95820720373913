<register-header [link]="'/login'"></register-header>

<form [formGroup]="resetPasswordForm" (ngSubmit)="resetPassword()">
    <h1 class="form-title text-center">Welcome to HubHive Connect</h1>
    <p>Reset Password</p>
    
    <div class="field-group fancy-shmancy">
        <div class="password-group">
            <input type="{{ showPassword ? 'text' : 'password' }}" formControlName="password" hasContent>
            <label>New Password</label>
            <img src="/assets/icon-eye-closed.svg" *ngIf="!showPassword" (click)="togglePasswordVisibility()">
            <img src="/assets/icon-eye-open.svg" *ngIf="showPassword" (click)="togglePasswordVisibility()">
        </div>
        <span *ngIf="password?.errors && password?.touched" class="error">Please type in a new password</span>
    </div>

    <div class="field-group" *ngIf="serverError">
        <p class="error">{{ serverError }}</p>
    </div>
    
    <div class="field-group">
        <button type="submit" class="btn btn-primary btn-rounded btn-submit">
            <span *ngIf="!isLoading">Continue</span>
            <loading-spinner *ngIf="isLoading"></loading-spinner>
        </button>
    </div>
</form>