import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { HeaderService } from 'src/app/_services';

@Component({
  selector: 'register-header',
  templateUrl: './register-header.component.html',
  styleUrl: './register-header.component.scss'
})
export class RegisterHeaderComponent implements OnChanges {
    @Input() link: string | null = null
    @Input() state: string = "login"
    
    constructor(private headerService: HeaderService) {
        this.headerService.setHeaderState(false, true)
    }
    
    ngOnChanges(changes: SimpleChanges): void {
        if(changes['link'] && changes['link'].currentValue) {
            this.link = changes['link'].currentValue
        }
    }
}
