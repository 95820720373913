import { Component, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { AuthenticationService } from 'src/app/_services';

@Component({
  selector: 'request-password-reset',
  templateUrl: './request-password-reset.component.html',
  styleUrl: './request-password-reset.component.scss'
})
export class RequestPasswordResetComponent implements OnDestroy {
    private subscriptions: Subscription = new Subscription()
    
    resetRequestForm: FormGroup
    isLoading: boolean = false
    serverError: string | null = null
    successMessage: string | null = null
    
    constructor(
        private fb: FormBuilder,
        private authService: AuthenticationService
    ) {
        this.resetRequestForm = this.fb.group({
            "email": new FormControl('', [Validators.required, Validators.email])
        })
    }
    
    ngOnDestroy(): void {
        this.subscriptions.unsubscribe()
    }
    
    get email() {
        return this.resetRequestForm.get('email')
    }
    
    sendResetRequest(): void {
        if(this.isLoading) {
            return
        }
        
        if(!this.resetRequestForm.valid) {
            this.resetRequestForm.markAllAsTouched()
            return
        }
        
        this.isLoading = true
        this.serverError = null
        let email = this.resetRequestForm.controls['email'].value
        
        this.subscriptions.add(
            this.authService.forgotPassword(email).subscribe({
                next: () => {
                    this.successMessage = 'If there is an account associated with that email address you will receive an email shortly with instructions to reset your password.'
                },
                error: (err) => {
                    this.serverError = err.error
                    this.isLoading = false
                },
                complete: () => {
                    this.isLoading = false
                }
            })
        )        
    }
}
