<register-header [link]="'/login'"></register-header>

<form [formGroup]="resetRequestForm" (ngSubmit)="sendResetRequest()">
    <h1 class="form-title text-center">Welcome to HubHive Connect</h1>
    <p>Forgot Password</p>
    
    <div class="field-group fancy-shmancy">
        <input type="text" formControlName="email" hasContent>
        <label>Email</label>
        
        <span *ngIf="email?.errors && email?.touched" class="error">Please type in your email</span>
    </div>

    <div class="field-group" *ngIf="serverError || successMessage">
        <p class="error" *ngIf="serverError">{{ serverError }}</p>
        <p class="success" *ngIf="successMessage">{{ successMessage }}</p>
    </div>
    
    <div class="field-group">
        <button type="submit" class="btn btn-primary btn-rounded btn-submit">
            <span *ngIf="!isLoading">Continue</span>
            <loading-spinner *ngIf="isLoading"></loading-spinner>
        </button>
    </div>
</form>