<div class="page-content">
    <div class="back" (click)="goBack()">
        <img src="/assets/icon-back-arrow.svg" alt="go back">
    </div>
    <img src="/assets/logo.svg" alt="hub hive logo">
    <h1 class="form-title text-center">Join the <br> Waitlist</h1>

    <form [formGroup]="waitlistForm" (ngSubmit)="submitWaitlistForm()"  autocomplete="off">
        <div class="field-group fancy-shmancy">
            <input type="text" formControlName="first_name" hasContent>
            <label>First Name</label>
            <span *ngIf="first_name.errors && first_name.touched" class="error">Name is required</span>
        </div>

        <div class="field-group fancy-shmancy">
            <input type="text" formControlName="last_name" hasContent>
            <label>Last Name</label>
            <span *ngIf="last_name.errors && last_name.touched" class="error">Last name is required</span>
        </div>
        
        <div class="field-group">
            <country-selector (countrySelected)="countrySelected($event)"></country-selector>
        </div>
        
        <div class="field-group fancy-shmancy phone-group">
            <span class="country-code">{{ selectedCountry?.dialCode }}</span>
            <input [ngClass]="{'has-content': selectedCountry}"
                phoneMask hasContent
                [preValue]="phone.value" 
                [phoneControl]="phone" 
                maxlength="14"
                formControlName="phone" 
                id="tel" type="tel" 
                class="phone-input">
            <label>Phone</label>
            
            <p *ngIf="phone.errors && phone.touched" class="error">Phone number is required</p>
            <p *ngIf="phone.errors && phone.touched && phone.errors['invalidPhone']" class="error">Invalid phone number</p>
        </div>

        <div class="field-group fancy-shmancy">
            <input type="email" formControlName="email" hasContent>
            <label>Email</label>
            <span *ngIf="email.errors && email.touched" class="error"></span>
            <span *ngIf="email.hasError('required') && email.touched" class="error">Email is required</span>
            <span *ngIf="email.hasError('email')" class="error">Invalid email format</span>
        </div>

        <div class="field-group">
            <label>I'm using HubHive as a(an):</label>
            <select formControlName="representing">
                <option value="" disabled>Select an option</option>
                <option *ngFor="let option of entityOptions" [value]="option">{{ option }}</option>
            </select>
            <span *ngIf="representing.errors && representing.touched" class="error">Selection is required</span>
        </div>

        <div class="field-group" *ngIf="representing.value === 'Business Owner' || representing.value === 'Community Organizer'">
            <label>What's Your Organization?</label>
            <input type="text" formControlName="entity_representing" hasContent>
            <span *ngIf="entity_representing?.errors && entity_representing?.touched" class="error">Organization is required</span>
        </div>

        <div class="field-group">
        <label>What Are Your Interests:</label>
        <div class="hh-checkbox">
            <div formGroupName="interests" class="interests">
                <div *ngFor="let interest of interestsOptions">
                    <label class="label">
                        <input type="checkbox" [formControlName]="interest" [id]="interest">
                        <span class="checkmark"></span>
                        {{ interest }}
                    </label>
                </div>
            </div>
        </div>
        </div>

        <div class="field-group" *ngIf="interests.get('Other')?.value">
            <label for="custom-interest">What is Your Interest?</label>
            <input type="text" formControlName="other_text" placeholder="Your Interest" id="custom-interest">
            <span *ngIf="other_text?.errors && other_text?.touched" class="error">Please specify your interest</span>
        </div>

        <div class="field-group">
            <label>How did you hear about HubHive?</label>
            <select formControlName="referred_by">
                <option value="" disabled>Select an option</option>
                <option *ngFor="let option of referredByOptions" [value]="option">{{ option }}</option>
            </select>
            <span *ngIf="referred_by.errors && referred_by.touched" class="error">This field is required</span>
        </div>

        <div class="field-group" *ngIf="referred_by.value === 'Other'">
            <label>Please specify</label>
            <input type="text" formControlName="referred_by_other" hasContent>
            <span *ngIf="referred_by_other?.errors && referred_by_other?.touched" class="error">Selection is required</span>
        </div>

        <div class="field-group">
            <div class="hh-checkbox">
                <label class="label">
                    <input type="checkbox" formControlName="wants_offers">
                    <span class="checkmark"></span>
                    Would you like to receive updates and exclusive offers from HubHive?
                </label>
            </div>
        </div>

        <button class="btn btn-primary btn-rounded btn-submit" type="submit">
            <span *ngIf="!isLoading">Submit</span>
            <loading-spinner *ngIf="isLoading"></loading-spinner>
        </button>
    </form>
</div>
<alert-popup #alertPopup></alert-popup>