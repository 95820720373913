<div class="back-header">
    <div class="back" (click)="goBack()">
        <img src="/assets/icon-back-black.svg" alt="back icon">
    </div>
</div>
<div class="code-container" [formGroup]="codeForm">
    <h2>Enter Code</h2>
    <p>We texted a code to your phone number.</p>
    <div class="input-group">
      <input
        *ngFor="let control of codeForm.controls | keyvalue; let i = index"
        type="text"
        maxlength="1"
        pattern="\d"
        [formControlName]="control.key"
        (input)="onInputChange(i)"
        (paste)="onPaste($event)"
        (keydown)="onBackspace(i, $event)"
        #inputField
      />
    </div>
    <span disabled *ngIf="!canResend">You can resend the code in {{ resendCountdown }} seconds</span>
    <span *ngIf="canResend" (click)="resendCode()" class="resend">Resend</span>
</div>
  
  